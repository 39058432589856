/* You can add global styles to this file, and also import other style files */

/* === Plugin styles === */


@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "../assets/styles/variables";
@import "~compass-mixins/lib/compass";
@import "~compass-mixins/lib/animate";
@import "~bootstrap/scss/bootstrap";
@import "../assets/styles/fonts";
@import "../assets/styles/functions";

/* === Icon fonts === */
@import "~@mdi/font/scss/materialdesignicons";


/* === Template mixins === */
@import "../assets/styles/mixins/animation";
@import "../assets/styles/mixins/badges";
@import "../assets/styles/mixins/buttons";
@import "../assets/styles/mixins/misc";
@import "../assets/styles/mixins/color-functions";
@import "../assets/styles/mixins/cards";
@import "../assets/styles/mixins/blockqoute";


/* === Core Styles === */
@import "../assets/styles/typography";
@import "../assets/styles/reset";
@import "../assets/styles/responsive";
@import "../assets/styles/misc";
@import "../assets/styles/utilities";
@import "../assets/styles/demo";
@import "../assets/styles/spinner";
@import "../assets/styles/dashboard";

/* === Components === */

@import "../assets/styles/components/checkbox-radio";
@import "../assets/styles/components/forms";
@import "../assets/styles/components/icons";
@import "../assets/styles/components/tables";
@import "../assets/styles/components/buttons";
@import "../assets/styles/components/breadcrumbs";
@import "../assets/styles/components/badges";
@import "../assets/styles/components/cards";
@import "../assets/styles/components/preview";
@import "../assets/styles/components/lists";
@import "../assets/styles/components/todo-list";
@import "../assets/styles/components/dropdowns";
@import "../assets/styles/components/loaders/loaders";

@import "../assets/styles/components/landing-screens/auth";

/* === Plugin Overrides === */
@import "../assets/styles/components/plugin-overrides/slick-carousel";
@import "../assets/styles/components/plugin-overrides/jquery-jvectormap";

/* === Layout === */
@import "../assets/styles/navbar";
@import "../assets/styles/sidebar";
@import "../assets/styles/footer";
@import "../assets/styles/layout";


.stretch-card_cms{
    .card{
        min-height:125px;
        max-height:125px;
    }
}

.stretch-card{
    .row{
        margin-left:0px;
        margin-right:0px;
    }
}
.steps_to_follow{
    .upload{
        display:flex;
        align-items: center;
        justify-content: space-between;
        h3{
            color:#000;
            font-size:16px;
            margin-top:15px;
            p{
                font-size:13px;
                color:#222;
                margin-top:10px;
            }
        }
    }
    h2{
        font-size:25px;
        color:#000;
    }
    .title_close{
        display:flex;
        align-items: center;
        justify-content: space-between;
        button{
            background:#f00;
            border:1px solid #fff;
            border-radius:50%;
            width: 30px;
            height:30px;
            display:flex;
            align-items: center;
            justify-content: center;
            color:#fff
        }
    }
}
.ReactModal__Content.ReactModal__Content--after-open{
    max-width: 300px;
    min-width:300px;
}
.ck.ck-editor__main{background:#121212 !important}
.form-control:disabled, .form-control[readonly]{
    color:#121212 !important
}

.ck.ck-editor__main>.ck-editor__editable{
    color: black !important;
    border-color: var(--ck-color-base-border);
}



.navbar_btn_design,.NFT.IDT button,.stretch-card button{
    background-color: #d5b26f;
    color: #000 !important;
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
    // border-radius: 36px !important;
    // padding: 3px 10px !important;
    border-radius: 6px !important;
    padding: 10px 14px !important;
  }
  .navbar_btn_design:hover{
    background-color: transparent !important;
    outline: 1px solid #d5b26f !important;
    color: #d5b26f !important;
  }
  .asrt-page-length .input-group-addon{
    background: transparent !important;
    color: #6c7293 !important;
  }
 .forms-sample .form-group input{
    color: #fff !important;
 }
 .ReactModal__Content.ReactModal__Content--after-open button{
    background-color: #d5b26f;
    color: #000 !important;
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
    border-radius: 36px !important;
    padding: 3px 10px !important;

 }
 .ReactModal__Content.ReactModal__Content--after-open button:hover{
    background-color: transparent !important;
    outline: 1px solid #d5b26f !important;
    color: #d5b26f !important;
 }

 .disabled.page-item {
    opacity: 0.5;
}

@media screen and (max-width:1280px){
.boxes {
    h3{
        font-size:20px;
    }
}
}
.sidebar .sidebar-brand-wrapper .sidebar-brand img{height: auto !important;}
.fa-eye:before {
    content: "\f06e";
}

.login_screenpage .password_input{
    position: relative;
}
.login_screenpage .password_input .eyeicon{
    position: absolute;
    right: 6px;
    top: 32%;
}
.sidebar.sidebar-offcanvas{
    min-height: 100vh !important;
}
.navbar.loginnavbar{
    left: 0px !important;
}
.userdetail_page .profile_img{
    display: flex;
    flex-direction: column;
}
.input-group.asrt-page-length .input-group-text{
    color: #9ca4da;
}
.table-foot.asrt-table-foot{
    margin-top: 20px;
}
.input-group.asrt-page-length .form-control{
    color: #fff;
}
.table-foot.asrt-table-foot .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #ffffff !important;
    background-color: #000000;
    border: 1px solid #767676;
}
.table-foot.asrt-table-foot .page-item.disabled .page-link{
    border: 1px solid #767676;
    background-color: #000000;
}
.table-foot.asrt-table-foot .page-link:focus{
    box-shadow: none;
}
.userdetail_page .profile_img img ,.token_detail_page .profile_img img{
    width: 50px;
    height: 50px;
    object-fit: cover;
}
.token_detail_page .profile_img {
    display: flex;
    flex-direction: column;
}
.cms_pagess .title_cont{
    font-size: 15px;
}
.date_flex
{
display: flex;
flex-direction: column;
}


// start of hariharan css

.react-select-new__control{
    background: #2A3038 !important;
    border: 1px solid #2c2e33 !important;
}
.react-select-new__single-value{
    color: white !important;
}
.react-select-new__menu-list{
    background-color: #2A3038 !important; 
    border: 1px solid #2c2e33 !important;
    cursor: pointer !important;

}
.react-select-new__option{
    cursor: pointer !important;
    background: none !important;

}
.react-select-new__option.react-select-new__option--is-selected{
    background-color: #d5b26f !important;
    color: #000 !important;
}
.react-select-new__option:active{
    background-color: #b0a89a !important;
    color: #000 !important;
}
.react-select-new__option:hover{
    background-color: #b0a89a !important;
    color: #000 !important;
}
.react-select-new__indicator-separator{
    display: none !important;
}
// end of hariharan css