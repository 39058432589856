
.datelist input{
  background-color: transparent !important;
  color: #fff !important;
  border: 1px solid #ffffffa1;
    padding: 4px 6px;
    border-radius: 2px;
}
.datelist input::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.bottom_btn_csv{
  background-color: #d5b26f;
    color: #000 !important;
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
    border-radius: 6px !important;
    padding: 10px 14px !important;
}
.table-responsive{
  padding-bottom: 20px;
}
.objectimg{
  width: 90px;
  height: 90px;
  object-fit: contain;
  margin-left: 15px;
}

#as-react-datatable-container{
  overflow-x: auto;
  padding-bottom: 15px;
}
// .table-responsive::-webkit-scrollbar-track
// {
// 	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
// 	border-radius: 10px;
// 	background-color: #F5F5F5;
// }

// .table-responsive::-webkit-scrollbar
// {
// 	width: 8px;
//   height: 10px;
// 	background-color: #F5F5F5;
// }

// .table-responsive::-webkit-scrollbar-thumb
// {
// 	border-radius: 10px;
// 	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
// 	background-color: #d5b26f;
// }

@media only screen and (max-width: 575px) {
  .datelist {
   display: block !important;
  }
}





/* Dashboard */
.dashboard-vector-map {
    width: 100%;
    height: 300px;
}
.auth .brand-logo img{width: 100% !important; border-radius: 10px;}
.sidebar .sidebar-brand-wrapper .sidebar-brand img {
  width: 75% !important;
border-radius: 5px;}
.proBanner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    .purchase-popup {
      @extend .grid-margin;
      background: #000;
      color: #fff;
      padding: 15px 20px;
      @include border-radius(3px);
  
      .btn {
        margin-right: 20px;
        font-weight: 500;
        color: $white;
        @include border-radius(5px);
        @include transition-duration(0.2s);
  
        &.download-button {
          background: rgba(249, 249, 249, 0.7);
          color: #969292;
          border: 1px solid darken(#e4e4e4,5%);
        }
  
        &.purchase-button {
          background-color: theme-color(info);
          color: $white;
          border: none;
          line-height: 1;
          vertical-align: middle;
        }
      }
  
      p {
        margin-bottom: auto;
        margin-top: auto;
        color: darken(#e4e4e4,20%);
        font-weight: 400;
        vertical-align: middle;
        line-height: 1;
      }
  
      i {
        vertical-align: middle;
        line-height: 1;
        margin: auto 0;
        color: darken(#e4e4e4,20%);
      }
      .bannerClose {
        cursor: pointer;
      }
    }
    &.hide {
      display: none;
    }
  }
  .error_msg{
    color:#f00;
  }